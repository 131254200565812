import {
  ERROR,
  GET_ALL_JOURNAL_ENTRIES_SUCCESS,
  ADD_JOURNAL_ENTRIES_SUCCESS,
  ADD_ACCOUNT_SUCCESS,
  GET_ALL_ACCOUNT_SUCCESS,
  GET_ALL_LEDGER_SUCCESS,
  GET_ALL_OPENING_SUCCESS,
  GET_ALL_TRADING_SUCCESS,
} from "../action_Types";

const initialState = {
  error: false,
  newJournalData: null,
  journalData: null,
  allAccountData: null,
  newAccountData: null,
  allLedgerData:null,
  openingBalance:null
};

const acountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_JOURNAL_ENTRIES_SUCCESS:
      return {
        ...state,
        error: false,
        journalData: action.payload.data,
      };

    case ADD_JOURNAL_ENTRIES_SUCCESS:
      return {
        ...state,
        error: false,
        newJournalData: action.payload,
      };

    case ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: false,
        newAccountData: action.payload,
      };

    case GET_ALL_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: false,
        newAccountData: null,
        allAccountData: action.payload,
      };

    case GET_ALL_OPENING_SUCCESS:
      return {
        ...state,
        openingBalance: action.payload,
        error: false,
      };

    case GET_ALL_TRADING_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case GET_ALL_LEDGER_SUCCESS:
      return {
        ...state,
        error: false,
        allLedgerData: action.payload,
      };

    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default acountingReducer;
