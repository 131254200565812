import axios from "axios";
import { API_BASE_URL } from "src/services/const";

const authInstance = axios.create({
  baseURL: API_BASE_URL
});

authInstance.interceptors.request.use(function (config) {

  let token = sessionStorage.getItem("token");

  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

export default authInstance;
