import { put, takeLatest, call } from "redux-saga/effects";
import authInstance from "../../api/apiConfig";
import {
  GET_ALL_PLAN,
  ERROR,
  GET_ALL_PLAN_SUCCESS,
  ADD_PLAN_DATA,
  ADD_PLAN_DATA_SUCCESS,
  DELETE_PLAN_BY_ID,
  DELETE_PLAN_BY_ID_SUCCESS,
  UPDATE_PLAN_BY_ID,
  UPDATE_PLAN_BY_ID_SUCCESS,
} from "../action_Types";

function* allPlan() {
  try {
    const response = yield call(authInstance.get, "loan-plans");

    yield put({
      type: GET_ALL_PLAN_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addPlan(action) {
  try {
    const response = yield call(
      authInstance.post,
      "loan-plans",
      action.payload
    );

    yield put({
      type: ADD_PLAN_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* deletePlan(action) {
  try {
    const response = yield call(
      authInstance.delete,
      `loan-plans/${action.payload}`
    );

    yield put({
      type: DELETE_PLAN_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updatePlan(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(authInstance.patch, `loan-plans/${id}`, data);
    yield put({
      type: UPDATE_PLAN_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

export default function* planActionWatcher() {
  yield takeLatest(GET_ALL_PLAN, allPlan);
  yield takeLatest(ADD_PLAN_DATA, addPlan);
  yield takeLatest(DELETE_PLAN_BY_ID, deletePlan);
  yield takeLatest(UPDATE_PLAN_BY_ID, updatePlan);
}
