import {
  ADD_PLAN_DATA_SUCCESS,
  ERROR,
  GET_ALL_PLAN_SUCCESS,
  SET_ROUTE_VALUE,
} from "../action_Types";
const initialState = {
  data: null,
  message: "",
  error: false,
  sidebarShow: true,
  routeValue: "",
};

const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PLAN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: true,
        sidebarShow: true,
      };
    case "set":
      return {
        ...state,
        sidebarShow: action.sidebarShow,
      };

    case ADD_PLAN_DATA_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
      };
    case SET_ROUTE_VALUE:
      return {
        ...state,
        update: true,
        error: false,
        routeValue: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default planReducer;
