import {
  GET_ALL_EMI_SUCCESS,
  ERROR,
  ADD_EMI_DATA_SUCCESS,
  // UPDATE_EMI_BY_ID_SUCCESS,
  SHOW_LOAN_SUMMARY_SUCCESS,
  GET_ALL_EMI_BY_CUSTOMER_ID_SUCCESS,
  CREATE_DISBURSEMENT_PAYMENT_SUCCESS,
  SET_CUSTOMER_DATA,
  GET_ALL_EMI_BY_EMI_STATUS_SUCCESS,
} from "../action_Types";

const initialState = {
  data: null,
  message: "",
  error: false,
  loanSummaryData: null,
  routeValue: "",
  emiData: null,
  customerData: null,
  emiStatusData: null
};

const emiReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EMI_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: true,
        paymentSuccess: false,
      };
    case GET_ALL_EMI_BY_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        emiData: action.payload,
        error: false,
      };
    case GET_ALL_EMI_BY_EMI_STATUS_SUCCESS:
      return {
        ...state,
        emiStatusData: action.payload,
        error: false,
      };
    case SET_CUSTOMER_DATA:
      return {
        ...state,
        customerData: action.payload,
        error: false,
      };

    case CREATE_DISBURSEMENT_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentSuccess: true,
        error: false,
      };

    case ADD_EMI_DATA_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
      };

    case SHOW_LOAN_SUMMARY_SUCCESS:
      return {
        ...state,
        loanSummaryData: action.payload,
        update: true,
        error: false,
      };

    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default emiReducer;
