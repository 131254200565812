import {
  ADMINISTRAOR_LOGIN_SUCCESS,
  ERROR,
  SELECTEDROLE,
} from "../action_Types";

const initialState = {
  logindata: null,
  message: "",
  error: false,
  selectedRoleType: "",
  update: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMINISTRAOR_LOGIN_SUCCESS:
      sessionStorage.setItem("emplyeeId", action?.payload?.data?.employeeId);
      return {
        ...state,
        update: true,
        error: false,
        logindata: action.payload,
        Id: action?.payload?.data?.employeeId,
      };
    case SELECTEDROLE:
      return {
        ...state,
        update: true,
        error: false,
        selectedRoleType: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
