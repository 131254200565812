// import React, { Suspense } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "../src/assets/styles/scss/style.scss";
// import Home from "./pages/Home";
// import Loading from "./components/loading/index";

// // Pages
// import Login from "./views/pages/login/Login";
// import Register from "./views/pages/register/Register";
// import Page404 from "./views/pages/page404/Page404";
// import Page500 from "./views/pages/page500/Page500";
// import Protected from "./Protected";
// import Users from "./pages/company";
// import AddCompanyForm from "./pages/company/companyAddForm";

// const route = () => {

//   return (
//     <BrowserRouter>
//       <Suspense fallback={<Loading />}>
//         <Routes>
//           <Route exact path="/login" name="Login Page" element={<Login />} />
//           <Route
//             exact
//             path="/register"
//             name="Register Page"
//             element={<Register />}
//           />
//           <Route exact path="/404" name="Page 404" element={<Page404 />} />
//           <Route exact path="/500" name="Page 500" element={<Page500 />} />
//           <Route
//             exact
//             path="/users/userList"
//             name="userList"
//             element={<Protected Cmp={Users} />}
//           />
//           <Route
//             exact
//             path="/users/addform"
//             name="addForm"
//             element={<Protected Cmp={AddCompanyForm} />}
//           />
//           <Route
//             path="*"
//             name="Home"
//             element={<Protected Cmp={Home} />}
//           ></Route>
//         </Routes>
//       </Suspense>
//     </BrowserRouter>
//   );
// };

// export default route;
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/assets/styles/scss/style.scss";
import Loading from "./components/loading/index";
import Protected from "./Protected";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./views/pages/login/Login"));
const Register = lazy(() => import("./views/pages/register/Register"));
const Page404 = lazy(() => import("./views/pages/page404/Page404"));
const Page500 = lazy(() => import("./views/pages/page500/Page500"));
const Users = lazy(() => import("./pages/company"));
const AddCompanyForm = lazy(() => import("./pages/company/companyAddForm"));

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          <Route path="/users/userList" element={<Protected Cmp={Users} />} />
          <Route path="/users/addform" element={<Protected Cmp={AddCompanyForm} />} />
          <Route path="*" element={<Protected Cmp={Home} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
