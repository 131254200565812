import { put, takeLatest, call } from "redux-saga/effects";
import authInstance from "../../api/apiConfig";
import {
  ERROR,
  GET_ALL_INTERESTS,
  GET_ALL_INTERESTS_SUCCESS,
  ADD_INTERESTS_DATA,
  EDIT_INTERESTS_DATA,
  ADD_INTERESTS_DATA_SUCCESS,
  DELETE_INTERESTS_BY_ID,
  DELETE_INTERESTS_BY_ID_SUCCESS,
  UPDATE_INTERESTS_BY_ID,
  UPDATE_INTERESTS_BY_ID_SUCCESS,
  EDIT_INTERESTS_DATA_SUCCESS,
} from "../action_Types";

function* allInterests(action) {
  try {
    const response = yield call(authInstance.get, "interests", action.payload);
    yield put({
      type: GET_ALL_INTERESTS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addInterests(action) {
  try {
    const response = yield call(authInstance.post, "interests", action.payload);
    yield put({
      type: ADD_INTERESTS_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* editInterests(action) {
  try {
    const id = action?.payload?.id || 0;

    const response = yield call(
      authInstance.patch,
      `interests/${id}`,
      action.payload
    );
    yield put({
      type: EDIT_INTERESTS_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* deleteInterests(action) {
  debugger
  try {
    const response = yield call(
      authInstance.delete,
      `interests/${action.payload}`
    );
    yield put({
      type: DELETE_INTERESTS_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateInterests(action) {
  try {
    const { id, data } = action.payload;

    const response = yield call(authInstance.patch, `interests/${id}`, data);
    yield put({
      type: UPDATE_INTERESTS_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

export default function* InterestsActionWatcher() {
  yield takeLatest(GET_ALL_INTERESTS, allInterests);
  yield takeLatest(ADD_INTERESTS_DATA, addInterests);
  yield takeLatest(EDIT_INTERESTS_DATA, editInterests);
  yield takeLatest(DELETE_INTERESTS_BY_ID, deleteInterests);
  yield takeLatest(UPDATE_INTERESTS_BY_ID, updateInterests);
}
