import { all } from "redux-saga/effects";
import planActionWatcher from "./planSagaActions";
import companyActionWatcher from "./companySagaAction";
import staffActionWatcher from "./staffSagaActions";
import emiActionWatcher from "./emiSagaAction";

import customerActionWatcher from "./customerSagaAction";
import InterestsActionWatcher from './loanListSagaAction';
import userActionWatcher from "./userSagaAction";
import accountingActionWatcher from "../accounting/sagaActions";

export default function* rootSaga() {
  yield all([
    planActionWatcher(),
    companyActionWatcher(),
    staffActionWatcher(),
    emiActionWatcher(),
    accountingActionWatcher(),
    customerActionWatcher(),
    InterestsActionWatcher(),
    userActionWatcher(),
    // userActionWatcher(),
  ]);
}
