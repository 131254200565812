import { applyMiddleware, legacy_createStore as createStore } from "redux";
import rootReducer from "./reducers";
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from "./sagaActions/index";

const sagaMiddleWare = createSagaMiddleware();
export  const store = createStore(rootReducer, applyMiddleware(sagaMiddleWare) );

sagaMiddleWare.run(rootSaga);

// import { legacy_createStore as createStore, applyMiddleware } from "redux";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";

// import rootReducer from "./reducers";
// import createSagaMiddleware from "@redux-saga/core";
// import rootSaga from "./sagaActions/index";

// const sagaMiddleWare = createSagaMiddleware();

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = createStore(
//   persistedReducer,
//   applyMiddleware(sagaMiddleWare)
// );

// sagaMiddleWare.run(rootSaga);

// export const persistor = persistStore(store);
