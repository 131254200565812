import { combineReducers } from "redux";
import companyReducer from "./companyReducer";
import planReducer from "./planReducers";
import staffReducer from "./staffReducer";
import customerReducer from "./customerReducer";
import userReducer from "./userReducer";
import emiReducer from "./emiReducer";
import interestsReducer from "./loanListReducer";
import authReducer from "./authReducer";
import acountingReducer from '../accounting/reducer'
const rootReducer = combineReducers({
  companyReducer,
  planReducer,
  staffReducer,
  customerReducer,
  emiReducer,
  interestsReducer,
  userReducer,
  acountingReducer,
  authReducer
});
export default rootReducer;
