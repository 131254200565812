import {
  ADD_STAFFS_DATA_SUCCESS,
  EDIT_STAFFS_DATA,
  ADMINISTRAOR_LOGIN_SUCCESS,
  ADMIN_LOGIN_SUCCESS,
  ERROR,
  GET_ALL_STAFFS_SUCCESS,
  SELECTEDROLE,
  SET_ROUTE_VALUE,
  SET_SCHEMANAME,
  STAFF_LOGIN_SUCCESS,
  CREATE_DISBURSEMENT_SUCCESS,
  GET_STAFF_BY_ID_SUCCESS,
  GET_STAFFS_BY_ID_SUCCESS,
  SET_PAYMENT_FALSE,
  EMPTY_STORE,
  EDIT_STAFFS_DATA_SUCCESS,
} from "../action_Types";

const initialState = {
  data: null,
  message: "",
  error: false,
  sidebarShow: true,
  routeValue: "",
  selectedRoleType: "",
  SCHEMANAME: "",
  update: false,
  disbursement: null,
  newUpdate: false,
  Id: null,
  branchData: null,
  updateData: false,
  staffOwnData: null,
  payment: false,
  newlogindata: null,
};

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STAFFS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: false,
        sidebarShow: true,
        staffData: null,
        staffOwnData: null,
        adminstratorData: null,
        adminData: null,
      };
    case "set":
      return {
        ...state,
        sidebarShow: action.sidebarShow,
      };

    case ADD_STAFFS_DATA_SUCCESS:
      return {
        ...state,
        newUpdate: true,
        update: true,
        error: false,
      };

    case EDIT_STAFFS_DATA_SUCCESS:
      return {
        ...state,
        updateData: true,
        update: true,
        error: false,
      };

    case "SET_UPDATE":
      return {
        ...state,
        newUpdate: false,
        updateData: false,
        error: false,
      };
    case GET_STAFFS_BY_ID_SUCCESS:
      return {
        ...state,
        staffOwnData: action.payload,
        error: false,
      };
    case EDIT_STAFFS_DATA:
      return {
        ...state,
        update: true,
        error: false,
      };
    case EMPTY_STORE:
      return {
        ...state,
        error: false,
        newlogindata: null,
        data: null,
      };
    case SET_SCHEMANAME:
      return {
        ...state,
        update: true,
        SCHEMANAME: action.payload,
        error: false,
      };
    case CREATE_DISBURSEMENT_SUCCESS:
      return {
        ...state,
        update: true,
        disbursement: action.payload,
        payment: true,
        error: false,
      };
    case SET_PAYMENT_FALSE:
      return {
        ...state,
        payment: false,
      };
    case GET_STAFF_BY_ID_SUCCESS:
      return {
        ...state,
        update: true,
        branchData: action?.payload?.data?.branch,
        error: false,
      };
    case SET_ROUTE_VALUE:
      return {
        ...state,
        update: true,
        error: false,
        routeValue: action.payload,
      };
    case STAFF_LOGIN_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
        staffData: action.payload,
      };
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
        adminData: action.payload,
      };
    case ADMINISTRAOR_LOGIN_SUCCESS:
      sessionStorage.setItem("emplyeeId", action?.payload?.data?.employeeId);
      return {
        ...state,
        update: true,
        error: false,
        newlogindata: action.payload,
        data: action.payload,
        Id: action?.payload?.data?.employeeId,
      };
    case SELECTEDROLE:
      return {
        ...state,
        update: true,
        error: false,
        selectedRoleType: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default staffReducer;
