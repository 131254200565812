import {
  ERROR,
  GET_ALL_USER_SUCCESS,
  ADD_USER_DATA_SUCCESS,
  REFRESH_PAGE,
  GET_ALL_DIRECTORS_SUCCESS,
  GET_ALL_BRANCHES_SUCCESS,
  GET_ALL_CAPITALS_SUCCESS,
  CREATE_BRANCHES_SUCCESS,
  PAGE_REFRESH,
  GET_ALL_LOAN_SUCCESS,
  GET_LOAN_BY_ID_SUCCESS,
  SET_DATA,
  CREATE_DIRECTORS_SUCCESS,
  CREATE_CAPITALS_SUCCESS,
} from "../action_Types";

const initialState = {
  data: null,
  message: "",
  error: false,
  update: false,
  Directors: null,
  Branches: null,
  Capitals: null,
  renderComp: false,
  loans: null,
  loandetails: null,
  loandata: null,
  newDirectorCreated: false,
  newCreatedData: null,
  newDirectorData: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: true,
      };
    case GET_ALL_DIRECTORS_SUCCESS:
      return {
        ...state,
        Directors: action.payload,
        error: false,
        update: true,
        newDirectorCreated: false,
      };
    case GET_LOAN_BY_ID_SUCCESS:
      return {
        ...state,
        loandetails: action.payload,
        error: false,
        update: true,
      };

    case SET_DATA:
      return {
        ...state,
        loandata: action.payload,
        error: false,
      };

    case GET_ALL_BRANCHES_SUCCESS:
      return {
        ...state,
        Branches: action.payload,
        error: false,
        update: true,
      };

    case GET_ALL_LOAN_SUCCESS:
      return {
        ...state,
        loans: action.payload,
        error: false,
      };

    case GET_ALL_CAPITALS_SUCCESS:
      return {
        ...state,
        Capitals: action.payload,
        newDirectorData: null,
        error: false,
        update: true,
      };

    case ADD_USER_DATA_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
      };
    case CREATE_BRANCHES_SUCCESS:
      return {
        ...state,
        renderComp: true,
        newCreatedData: action.payload,
        error: false,
      };
    case PAGE_REFRESH:
      return {
        ...state,
        renderComp: false,
        error: false,
      };
    case REFRESH_PAGE:
      return {
        ...state,
        update: true,
        error: false,
      };
    case CREATE_DIRECTORS_SUCCESS:
      return {
        ...state,
        newDirectorCreated: true,
      };
    case CREATE_CAPITALS_SUCCESS:
      return {
        ...state,
        newDirectorData: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default customerReducer;
