import { successAlert } from "src/utils/Swal";
import {
  AADHAR_VERIFICATION_OTP_SUCCESS,
  CREATE_AADHAR_VERIFICATION_SUCCESS,
  DELETE_COMPANY_BY_ID_SUCCESS,
  DELETE_LOAN_BY_ID_SUCCESS,
  ERROR,
  GET_ALL_COMPANY_SUCCESS,
  NEW_ERROR,
  NEW_VERY_ERROR,
  RESET_VERIFICATION,
  UPDATE_COMPANY_BY_ID_SUCCESS,
  UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID_SUCCESS
} from "../action_Types";
const initialState = {
  data: null,
  message: "",
  error: false,
  update: false,
  verifiedData: null,
  otpVerifiedData: null,
  newOtpVerifiedData: null,
  deletedData: null,
  ere:false,
  errordata:null,
  verifiedMessage:null,
  errVe:false,
  adharNumber:null
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPANY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: true,
      };

    case DELETE_LOAN_BY_ID_SUCCESS:
      return {
        ...state,
        deletedData: action.payload,
        error: false,
        update: true,
      };

    case UPDATE_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        error: false,
        update: true,
      };
      case UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID_SUCCESS:
      successAlert("Success", "Company Aaddhar Token Limit Successfully Updated");
        return {
          ...state,
          error: false,
          update: true,
        };
    case CREATE_AADHAR_VERIFICATION_SUCCESS:
      return {
        ...state,
        verifiedData: action.payload,
        verifiedMessage: action.payload,
        error: false,
        ere:false,
        update: true,
      };
    case "reseeeet":
      return {
        ...state,
        verifiedData: null,
        error: false,
        ere:false,
        errVe:false,
        update: true,
      };
    case AADHAR_VERIFICATION_OTP_SUCCESS:
      return {
        ...state,
        otpVerifiedData: action.payload,
        newOtpVerifiedData: action.payload,
        error: false,
        newotpdata: true,
      };
    case RESET_VERIFICATION:
      return {
        ...state,
        otpVerifiedData: null,
        error: false,
      };
    case NEW_ERROR:
      return {
        ...state,
        otpVerifiedData: null,
        errordata:action.payload,
        ere:true,
      };
    case NEW_VERY_ERROR:
      return {
        ...state,
       errVe : true
      };
    case "aadharNumber":
      return {
        ...state,
       adharNumber : action.payload
      };
    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;
