import { put, takeLatest, call } from "redux-saga/effects";
import authInstance from "../../api/apiConfig";
import {
  ERROR,
  GET_ALL_JOURNAL_ENTRIES,
  GET_ALL_JOURNAL_ENTRIES_SUCCESS,
  ADD_JOURNAL_ENTRIES,
  ADD_JOURNAL_ENTRIES_SUCCESS,
  GET_ALL_CONTRA_ENTRIES,
  GET_ALL_CONTRA_ENTRIES_SUCCESS,
  ADD_CONTRA_ENTRIES_SUCCESS,
  ADD_CONTRA_ENTRIES,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT,
  GET_ALL_ACCOUNT,
  GET_ALL_ACCOUNT_SUCCESS,
  GET_ALL_LEDGER,
  GET_ALL_LEDGER_SUCCESS,
  GET_ALL_OPENING_SUCCESS,
  GET_ALL_OPENING,
  GET_ALL_TRADING,
  GET_ALL_TRADING_SUCCESS,
} from "../action_Types";

function* getAllJournalEntries(action) {
  try {
    const response = yield call(
      authInstance.post,
      "getAllJournals",
      action.payload
    );

    yield put({
      type: GET_ALL_JOURNAL_ENTRIES_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addJournalEntry(action) {
  try {
    const response = yield call(
      authInstance.post,
      "addJournal",
      action.payload
    );

    yield put({
      type: ADD_JOURNAL_ENTRIES_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addAccount(action) {
  try {
    const response = yield call(
      authInstance.post,
      "addAccount",
      action.payload
    );

    yield put({
      type: ADD_ACCOUNT_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getAllAccount(action) {
  try {
    const response = yield call(
      authInstance.post,
      "getAllAccounts",
      action.payload
    );

    yield put({
      type: GET_ALL_ACCOUNT_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getAllContraEntries() {
  try {
    const response = yield call(authInstance.get, "");

    yield put({
      type: GET_ALL_CONTRA_ENTRIES_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getAllLedger(action) {
  try {
    const response = yield call(
      authInstance.post,
      "accountledger",
      action.payload
    );
    yield put({
      type: GET_ALL_LEDGER_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getOpeningBalance(action) {
  try {
    const response = yield call(
      authInstance.post,
      "/balanceSheet",
      action.payload
    );
    yield put({
      type: GET_ALL_OPENING_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getTradingAccount(action) {
  try {
    const response = yield call(
      authInstance.post,
      "",
      action.payload
    );
    yield put({
      type:GET_ALL_TRADING_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addContraEntry(action) {
  try {
    const response = yield call(authInstance.post, "", action.payload);

    yield put({
      type: ADD_CONTRA_ENTRIES_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

export default function* accountingActionWatcher() {
  yield takeLatest(ADD_JOURNAL_ENTRIES, addJournalEntry);
  yield takeLatest(GET_ALL_OPENING, getOpeningBalance);
  yield takeLatest(GET_ALL_TRADING, getTradingAccount);
  yield takeLatest(ADD_ACCOUNT, addAccount);
  yield takeLatest(GET_ALL_ACCOUNT, getAllAccount);
  yield takeLatest(GET_ALL_LEDGER, getAllLedger);
  yield takeLatest(GET_ALL_JOURNAL_ENTRIES, getAllJournalEntries);
  yield takeLatest(ADD_CONTRA_ENTRIES, addContraEntry);
  yield takeLatest(GET_ALL_CONTRA_ENTRIES, getAllContraEntries);
}
